import * as React from 'react';
// import { useHistory } from 'react-router-dom';
import { StyledLogin } from './Login.styles';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts/Auth.context';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
// import { BASE_URL } from '../../../configurations/url.config';
// import { auth } from '../../../configurations/fireBase';
// import { signInWithPopup } from '@firebase/auth';
// import axios from 'axios';
import { metaConfig } from '../../../utils/constants';
import { getDeviceToken } from '../../../services/pushNotification/pushNotification.service';

// << ---- Class or interface definition  starts ---- >>
interface FormValues {
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  isPhoneVerified: boolean;
  userName: string;
  deviceToken: any;
}

// << ---- Class or interface definition  Ends ---- >>

// << ---- Register component starts  ---- >>
export const Register = () => {
  // << ---- Used hooks
  const navigate = useNavigate();
  const location = useLocation();

  // << ---- components properties starts ------------------------- >>
  // const [userNames, setUserNames] = useState<string[]>([]);
  const authContex = React.useContext(AuthContext);
  // const [userName, setUserName] = useState<string>('')
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: location?.state?.phoneNumber,
    password: '',
    confirmPassword: '',
    isPhoneVerified: true,
    userName: '',
    deviceToken: '',
  });
  const [formErrors, setFormErrors] = useState<FormValues>({
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    isPhoneVerified: true,
    userName: '',
    deviceToken: '',
  });

  // const [createShowPassword, setCreateShowPassword] = React.useState(false);
  // const [confirmShowPassword, setConfirmShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [isShowingSplash, setIsShowingSplash] = useState(false);

  // << Function definition  starts ------------------------------
  // const generateUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   //const { name, value } = event.target;
  //   if (formValues.firstName.length >= 4) {
  //     let count = 4;
  //     while (count > 0) {
  //       let uname = formValues.firstName + formValues.lastName.slice(0, 3) + Math.floor(1000 + Math.random() * 9000);
  //       userNames.push(uname.toLocaleLowerCase());
  //       // count = count -1;
  //       --count;
  //     }
  //     // console.log(userNames)

  //   }
  // }

  // const generateUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // Assuming formValues is accessible here
  //   const { firstName, lastName } = formValues;
  //   const newNames: string[] = [];
  //   if (firstName.length >= 4) {
  //     let count = 12;
  //     while (count > 0) {
  //       let uname =
  //         firstName +
  //         (lastName ? lastName.slice(0, 3) : '') +
  //         Math.floor(1000 + Math.random() * 9000);
  //       newNames.push(uname.toLocaleLowerCase());
  //       --count;
  //     }
  //   }
  //   setUserNames(newNames);
  // };

  // const handleUserName = (event: any) => {
  //   const { outerText } = event.target;
  //   // setUserName(outerText)
  //   setFormValues({ ...formValues, userName: outerText });
  // };



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };
  const handleCloseSnackbar = () => {
    setErrorMessage(null);
  };



  // const handleSubmit = async (e: any) => {
  //   let deviceToken = localStorage.getItem('deviceToken');
  //   if (!deviceToken) {
  //     deviceToken = await getDeviceToken();
  //     if (deviceToken) {
  //       localStorage.setItem('deviceToken', deviceToken);
  //     } else {
  //       console.warn('Device token is unavailable or permission is denied.');
  //     }
  //   }
  //   e.preventDefault();
  //   const errors: FormValues = {
  //     firstName: '',
  //     lastName: '',
  //     emailId: '',
  //     phoneNumber: '',
  //     password: '',
  //     confirmPassword: '',
  //     isPhoneVerified: true,
  //     userName: '',
  //     deviceToken: '',
  //   };
  //   if (!formValues.firstName) {
  //     errors.firstName = 'Please enter your First Name';
  //   } else if (!/^[A-Z,a-z ,.'-]+$/.test(formValues.firstName)) {
  //     errors.firstName = 'Special characters not allowed';
  //   } else if (
  //     formValues.firstName.length < 4 ||
  //     formValues.firstName.length > 50
  //   ) {
  //     errors.firstName = 'Please enter min 3 or max 50 characters';
  //   }
  //   if (!formValues.emailId) {
  //     errors.emailId = 'Please enter your email ID';
  //   } else if (!/\S+@\S+\.\S+/.test(formValues.emailId)) {
  //     errors.emailId = 'Please enter a valid Email ID';
  //   }
  //   if (!formValues.phoneNumber) {
  //     errors.phoneNumber = 'Please enter Mobile Number';
  //   } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
  //     errors.phoneNumber = 'Please enter valid Mobile Number';
  //   }
  //   setFormErrors(errors);
  //   if (formValues.password !== formValues.confirmPassword) {
  //     return;
  //   }
  //   const updatedFormValues = {
  //     ...formValues,
  //     deviceToken: deviceToken || '',
  //   };
  //   const data = JSON.stringify(updatedFormValues);
  //   const response: any = await authContex.autoCreate(data);
  //   try {
  //     setIsLoading(true);
  //     if (response.status === 200) {
  //       console.log(response, "response");
  //       navigate('/explore-communities', {
  //         state: { id: 2, data: response?.data },
  //       });
  //       enqueueSnackbar('Account created successfully', {
  //         variant: 'success',
  //       });
  //     } else if (response?.response.status === 500) {
  //       console.log('response', response);
  //       //setError('Your not found!');
  //       navigate('/register');
  //     } else if (
  //       response?.response.data &&
  //       Array.isArray(response.response.data)
  //     ) {
  //       // Handle array of errors
  //       const errorMessages = response.response.data.map(
  //         (error: { msg: string }) => error.msg
  //       );
  //       setErrorMessage(errorMessages.join(', ')); // Set the error message
  //     } else {
  //       console.log(response.response.data.message, 'error');
  //       setErrorMessage(response.response.data.message);
  //     }
  //   } catch (error) {
  //     console.log('error');
  //   } finally {
  //     setIsLoading(false);
  //   }
  //   //setLoading(false);
  //   // const data1 = JSON.parse(data);
  //   //console.log(data);
  //   // console.log(data1)
  // };


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let deviceToken = localStorage.getItem("deviceToken");
    if (!deviceToken) {
      deviceToken = await getDeviceToken();
      if (deviceToken) {
        localStorage.setItem("deviceToken", deviceToken);
      } else {
        console.warn("Device token is unavailable or permission is denied.");
      }
    }
    const errors: FormValues = {
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      isPhoneVerified: true,
      userName: "",
      deviceToken: "",
    };

    if (!formValues.firstName) {
      errors.firstName = "Please Enter your First Name";
    } else if (!/^[A-Z,a-z ,.'-]+$/.test(formValues.firstName)) {
      errors.firstName = "Special characters not allowed";
    } else if (
      formValues.firstName.length < 4 ||
      formValues.firstName.length > 50
    ) {
      errors.firstName = "Please Enter min 3 or max 50 characters";
    }

    if (!formValues.emailId) {
      errors.emailId = "Please Enter your Email ID";
    } else if (!/\S+@\S+\.\S+/.test(formValues.emailId)) {
      errors.emailId = "Please Enter a valid Email ID";
    }
    if (!formValues.phoneNumber) {
      errors.phoneNumber = "Please enter Mobile Number";
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      errors.phoneNumber = "Please enter valid Mobile Number";
    }

    setFormErrors(errors);
    if (formValues.password !== formValues.confirmPassword) {
      return;
    }
    const updatedFormValues = {
      ...formValues,
      deviceToken: deviceToken || "",
    };
    const data = JSON.stringify(updatedFormValues);
    try {
      setIsLoading(true);
      const response: any = await authContex.autoCreate(data);
      if (response.status === 200) {
        const { community, ...userData } = response.data;

        if (community) {
          navigate("/explore-communities", {
            state: { id: 2, data: response.data },
          });
        } else {
          navigate("/explore-communities", {
            state: { id: 2, data: userData },
          });
        }
        enqueueSnackbar("Account Created successfully", {
          variant: "success",
        });
      } else if (
        response?.response?.data &&
        Array.isArray(response.response.data)
      ) {
        const errorMessages = response.response.data.map(
          (error: { msg: string }) => error.msg
        );
        setErrorMessage(errorMessages.join(", "));
      } else {
        console.log(response.response.data.message, "error");
        setErrorMessage(response.response.data.message);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };




  React.useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);

      setFormValues({
        ...formValues,
        firstName: userInfo.given_name || '',
        lastName: userInfo.family_name || '',
        emailId: userInfo.email || '',
        // Update other form fields as needed
      });
    }
    // eslint-disable-next-line
  }, []);



  // <<<<<<<<<< HTML COMPONENT STARTS HERE >>>>>>>>>>>>>
  return (
    <>
      <Helmet>
        <title>{metaConfig.title.loginPage}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <StyledLogin>
        <Grid container>
          <Grid
            item
            md={7}
            sx={{
              background: 'linear-gradient(to right bottom, #50a1ca,#469EA7)',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ height: '100%', width: '100%' }}
            >
              <Box sx={{ cursor: 'pointer', px: 2 }}>
                <Box
                  component={'img'}
                  src={require('./../../../assets/images/registerbckg.png')}
                  alt=""
                  maxWidth={'100%'}
                />
              </Box>
              <Stack alignItems={'center'} sx={{ mt: 1 }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: '28px',
                    fontWeight: '700',
                    color: '#ffffff',
                    fontFamily: 'Inter',
                  }}
                >
                  Unlock Community Access
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#ffffff',
                    fontFamily: 'Montserrat',
                    pt: 1,
                  }}
                >
                  Take the first step to embrace the limitless possibilities
                  that await you here.
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              sx={{
                height: '100vh',
                overflow: 'auto',
                alignItems: 'center',
                justifyContent: { xs: 'flex-start', md: 'center' },
              }}
              className="login-form-container"
              alignItems={'center'}
            >
              {/* <Box
                sx={{}}
                component={"img"}
                src={require("./../../../assets/images/Communn-new-logo.png")}
                alt=""
                width={"30%"}
              /> */}
              <Box>
                <Stack
                  direction={'row'}
                  sx={{
                    pr: 10,
                    pl: 2,
                    pt: 0.5,
                    display: { xs: 'none', md: 'block' },
                  }}
                >
                  <ArrowBackIcon
                    sx={{ color: '#1C1B1F', cursor: 'pointer' }}
                    onClick={() => navigate('/login')}
                  />
                </Stack>
                <Divider
                  sx={{ pt: 1, display: { xs: 'none', md: 'block' } }}
                ></Divider>

                <Box
                  component={'img'}
                  src={require('./../../../assets/images/RegisterMBg.png')}
                  sx={{
                    height: '180px',
                    width: '100%',
                    display: { xs: 'block', md: 'none' },
                  }}
                  alt=""
                ></Box>
                <Box
                  component={'form'}
                  sx={{
                    pt: { xs: 3, sm: 3, md: 3, lg: 3, xxl: 5 },
                    mt: { xs: '5vh', sm: '5vh', md: '0vh', lg: '0vh' },
                    width: { xs: 'auto', md: '328px' },
                    px: { xs: 5, md: 0 },
                  }}
                >
                  <Stack direction={'row'} justifyContent={'center'}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#424242',
                        fontFamily: 'Inter',
                        pb: 3,
                      }}
                    >
                      Register
                    </Typography>
                    {/* <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#424242",
                        fontFamily: "Inter",
                      }}
                    >
                      Skip
                    </Typography> */}
                  </Stack>
                  <Stack>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChange(e);

                          }}
                          value={formValues.firstName}
                          fullWidth
                          label="Name"
                          id="outlined-size-small"
                          size="small"
                          type="text"
                          name="firstName"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: 'pointer',
                                  backgroundColor: '#E5E3EF',
                                  borderRadius: '100px',
                                }}
                              ></InputAdornment>
                            ),
                            style: {
                              borderRadius: '30px',
                              backgroundColor: '#ffffff',
                            },
                          }}
                        />
                        {/* {formErrors.firstName && (
                          <Stack sx={error}>{formErrors.firstName}</Stack>
                        )} */}
                      </Grid>

                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          value={formValues.lastName}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChange(e);
                            generateUserName(e);
                          }}
                          fullWidth
                          label="Last Name"
                          id="outlined-size-small"
                          size="small"
                          type="text"
                          name="lastName"
                          sx={label}
                        />
                        {formErrors.lastName && (
                          <Stack sx={error}>{formErrors.lastName}</Stack>
                        )}
                      </Grid> */}
                    </Grid>
                  </Stack>

                  {/* {userNames.length > 0 ? (
                    <Stack>
                      <Grid container spacing={2} sx={{ pt: 4 }}>
                        <Grid item xs={12}>
                          <Typography sx={{ fontSize: "15px", fontWeight: 400, pb: 1 }}>
                            Available usernames
                          </Typography>

                          <Grid container spacing={1}>
                            {userNames.slice(0, 12).map((e, index) => (
                              <Grid item key={index} xs={6} sm={6} md={6} lg={3}>
                                <Chip
                                  label={e}
                                  component="a"
                                  sx={{
                                    color: "#50A1CA",
                                    border: "1px solid #50A1CA",
                                    marginBottom: 1,
                                  }}
                                  variant="outlined"
                                  onClick={(e: any) => handleUserName(e)}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Stack>


                  ) : (
                    ""
                  )} */}

                  {/* <Stack>
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid item xs={12}>
                        <TextField
                          value={formValues.userName}
                          fullWidth
                          label="Create User Name"
                          size="small"
                          name="userName"
                          onChange={handleChange}
                        />
                        {formErrors.userName && (
                          <Stack sx={error}>{formErrors.userName}</Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Stack> */}

                  <Stack>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      <Grid item xs={12}>
                        <TextField
                          aria-readonly
                          value={formValues.phoneNumber}
                          onChange={handleChange}
                          fullWidth
                          label="Mobile"
                          type="tel"
                          size="small"
                          name="phoneNumber"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: 'pointer',
                                  backgroundColor: '#E5E3EF',
                                  borderRadius: '100px',
                                }}
                              ></InputAdornment>
                            ),
                            style: {
                              borderRadius: '30px',
                              backgroundColor: '#ffffff',
                            },
                          }}
                        />
                        {/* {formErrors.phoneNumber && (
                          <Stack sx={error}>{formErrors.phoneNumber}</Stack>
                        )} */}
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      <Grid item xs={12}>
                        <TextField
                          value={formValues.emailId}
                          onChange={handleChange}
                          fullWidth
                          label="Email"
                          type="email"
                          size="small"
                          name="emailId"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: 'pointer',
                                  backgroundColor: '#E5E3EF',
                                  borderRadius: '100px',
                                }}
                              ></InputAdornment>
                            ),
                            style: {
                              borderRadius: '30px',
                              backgroundColor: '#ffffff',
                            },
                          }}
                        />
                        {/* {formErrors.emailId && (
                          <Stack sx={error}>{formErrors.emailId}</Stack>
                        )} */}
                      </Grid>
                    </Grid>
                  </Stack>

                  {/* <Stack>
                    <Grid container spacing={3} sx={{ pt: 4 }}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={formValues.password}
                          onChange={handleChange}
                          size="small"
                          name="password"
                          fullWidth
                          type={createShowPassword ? "text" : "password"}
                          label={"Create Password"}
                          placeholder="Create Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                onClick={handleClickCreateShowPassword}
                                position="start"
                                sx={{ cursor: "pointer" }}
                              >
                                {createShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiInputBase-root-MuiOutlinedInput-root": {
                              pr: 0
                            }
                          }}
                        />
                        {formErrors.password && (
                          <Stack sx={error}>{formErrors.password}
                          </Stack>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={formValues.confirmPassword}
                          onChange={handleChange}
                          name="confirmPassword"
                          size="small"
                          fullWidth
                          type={confirmShowPassword ? "text" : "password"}
                          label={"Confirm Password"}
                          placeholder="Password"
                          sx={{
                            "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
                              pr: 0
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                onClick={handleClickConfirmShowPassword}
                                position="start"
                                sx={{ cursor: "pointer" }}
                              >
                                {confirmShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {formErrors.confirmPassword && (
                          <Stack sx={error}>{formErrors.confirmPassword}</Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Stack> */}

                  <Grid container sx={{ mt: 3 }}>
                    <Grid item>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignContent={'center'}
                        justifyContent={'center'}
                      >
                        <Button
                          size="large"
                          variant="contained"
                          type="submit"
                          onChange={(e: any) => setFormValues(e.target.value)}
                          onClick={handleSubmit}
                          fullWidth
                          sx={{
                            background:
                              'linear-gradient(to right bottom, #50a1ca, #3b9b7f)',
                            textTransform: 'capitalize',
                            borderRadius: '100px',
                            fontSize: '16px',
                            width: 210,
                            boxShadow: 'none',
                          }}
                        >
                          {isLoading ? (
                            <CircularProgress size={24} sx={{ color: 'white' }} />
                          ) : (
                            'Save & Continue'
                          )}
                        </Button>
                      </Stack>
                      {errorMessage && (
                        <Snackbar
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(errorMessage)}
                          autoHideDuration={6000}
                          onClose={handleCloseSnackbar}
                        >
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleCloseSnackbar}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                            onClose={handleCloseSnackbar}
                          >
                            <AlertTitle>Error</AlertTitle>
                            {errorMessage}
                          </Alert>
                        </Snackbar>
                      )}
                      {/* <Divider sx={{ pt: 2 }}>
                        <Box component={"span"}>OR Continue With</Box>
                      </Divider> */}
                      {/* <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"center"}
                        alignContent={"center"}
                        sx={{ pt: 2 }}
                      >
                        <Fab size="medium">
                          <Box
                            onClick={() => handleGoogleLogin()}
                            component={"img"}
                            src={require("./../../../assets/images/google.png")}
                          />
                        </Fab>
                        <Fab size="medium">
                          <Box
                            onClick={() => handleFacebookLogin()}
                            component={"img"}
                            src={require("./../../../assets/images/facebook.png")}
                          />
                        </Fab>
                      </Stack> */}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </StyledLogin>
    </>
  );
  // <<<<<<<<<< HTML COMPONENT ENDS HERE >>>>>>>>>>>>>>>>
};
// << ---- Register component ends  ---- >>
