import { Box, Button, Stack, Tab, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AllClass from './Components/AllClasses';
import React from 'react';
import PastClasses from './Components/PastClasses';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import { SubNavbar } from '../Layout/components/SubNavbar';
import { useNavigate } from 'react-router-dom';

const LiveClass = () => {
  const [value, setValue] = React.useState('1');

  const navigate = useNavigate()

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.plans}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>

      <SubNavbar>
        <Stack direction={'row'} flexGrow={1} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
          <Typography
            sx={{
              fontSize: { xs: '15px', md: '20px' },
              fontWeight: 500,
              color: '#3C5AA0',
              fontFamily: 'Inter',
            }}
          >
            Scheduled Classes
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddCircleRoundedIcon />}
            sx={{
              textTransform: 'capitalize',
              borderRadius: '30px',
              backgroundColor: '#3C5AA0',
              fontFamily: 'Inter',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3C5AA0',
                cursor: 'pointer',
                border: 'none',
              },
            }}
            onClick={() => {
              navigate('/create-class')
            }}
          >
            Add New
          </Button>
        </Stack >
      </SubNavbar>
      <Box sx={{ width: '100%', mt: 2 }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="All Classes"
                value="1"
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                  color: '#92929D',
                  '&.Mui-selected': {
                    color: '#3760A9',
                    fontWeight: 600,
                  },
                }}
              />
              <Tab
                label="Past Classes"
                value="2"
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                  color: '#92929D',
                  '&.Mui-selected': {
                    color: '#3760A9',
                    fontWeight: 600,
                  },
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ px: 0 }}>
            <AllClass />
          </TabPanel>
          <TabPanel value="2" sx={{ px: 0 }}>
            <PastClasses />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default LiveClass;
