import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MenuIcon } from '../../../../assets/icons';
import { Button, Divider, Stack } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector } from 'react-redux';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import FormatIndentDecreaseRoundedIcon from '@mui/icons-material/FormatIndentDecreaseRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function MobileSideBar() {
  const [state, setState] = React.useState({
    left: false,
  });

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  const community_id = selectedCommunity?._id;

  const link = `/community/${community_id}/about`;

  const icons = [
    <HomeRoundedIcon />,
    <AssignmentRoundedIcon />,
    <PeopleRoundedIcon />,
    <PaymentsRoundedIcon />,
    <AssessmentRoundedIcon />,
    <AccountBalanceWalletRoundedIcon />,
    <GroupAddRoundedIcon />,
    <Groups2RoundedIcon />,
    <AutoStoriesRoundedIcon />,
    <Diversity2RoundedIcon />,
    <InfoRoundedIcon />,
    <PersonAddRoundedIcon />,
  ];

  const allMenuItems = [
    { text: 'Home', link: '/home' },
    { text: 'Manage Posts', link: '/posts' },
    { text: 'Manage Members', link: '/users' },
    { text: 'Manage Payments', link: '/payments' },
    { text: 'Manage Reports', link: '/subscription-reports' },
    { text: 'Manage Plans   ', link: '/plans' },
    { text: 'Create Subscription', link: '/create-subscription' },
    { text: 'Manage Subscribers', link: '/subscribers' },
    { text: 'Manage Course', link: '/courses' },
    { text: 'Communities', link: '/communities' },
    { text: 'About Community', link: link },
    { text: 'Invite Members', link: '/invitations' },
  ];

  // Combine menu items and icons into a single array
  const combinedItems = allMenuItems.map((item, index) => ({
    ...item,
    icon: icons[index],
  }));

  // Filter out the "Manage Plans" item if collectSubscription is "NO"
  const filteredItems =
    selectedCommunity?.collectSubscription === 'NO'
      ? combinedItems.filter((item) => item.text !== 'Manage Plans')
      : combinedItems;

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const location = useLocation();

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300,
        p: 2,
      }}
      role="presentation"
    >
      <Stack
        display={'flex'}
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ alignItems: 'center' }}
      >
        <Link
          to="/home"
          style={{ textDecoration: 'none' }}
          onClick={toggleDrawer(anchor, false)}
        >
          <Box
            component={'img'}
            src={require('../../../../assets/images/Communn-Logo.png')}
            alt=""
            sx={{ width: '80%' }}
          />
        </Link>
        <IconButton
          onClick={toggleDrawer(anchor, false)}
          sx={{ border: '1px solid #515151 ' }}
        >
          <ClearIcon sx={{ color: '#515151' }} />
        </IconButton>
      </Stack>
      <List>
        {filteredItems.map((item) => (
          <React.Fragment key={item.text}>
            <ListItem disablePadding sx={{ fontSize: '20px' }}>
              <ListItemButton
                component={Link}
                to={item.link}
                onClick={toggleDrawer(anchor, false)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '35px',
                    color:
                      location.pathname === item.link ? '#3C5AA0' : '#777777',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{
                    '& .css-10hburv-MuiTypography-root': {
                      fontSize: '12px',
                      color:
                        location.pathname === item.link ? '#3C5AA0' : '#777777',
                      fontFamily: 'Inter',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <Stack
        display={'flex'}
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ mt: 10 }}
        alignItems={'center'}
      >
        <Link to="/explore-communities  " style={{ textDecoration: 'none' }}>
          <Button
            variant="outlined"
            sx={{
              color: '#50A1CA',
              border: '1px solid #50A1CA',
              borderRadius: '30px',
              fontSize: '14px',
              textTransform: 'capitalize ',
              fontWeight: '400',
            }}
            startIcon={
              <FormatIndentDecreaseRoundedIcon sx={{ fontSize: '25px' }} />
            }
          >
            Explore
          </Button>
        </Link>
        {/* <MobileSwiper /> */}
      </Stack>
    </Box>
  );

  return (
    <div>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)} sx={{ p: 0 }}>
            <MenuIcon sx={{ color: '#92929D', p: 0 }} />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
