import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  Typography,
  Stack,
} from '@mui/material';
// import { useCommunity } from "./../../../hooks/useCommunity";
import { ICommunity } from '../../../models/communities.modal';
import { USER_APP_BASE_URL } from '../../../configurations/url.config';
import { useSnackbar } from 'notistack';
import SuggestionsPreview from './SuggestionsPreview';

interface SuggestionsListProps extends ICommunity {
  refetchData: () => void;
}

export const SuggestionsList: React.FC<SuggestionsListProps> = (props) => {
  // console.log(props,"data");
  const { refetchData } = props;

  // export const SuggestionsList = (props: ICommunity) => {

  // const { getSuggestionCommunities } = useCommunity();
  // const [trendingCommunities, setTrendingCommunities] = useState<ICommunity[]>(
  //   []
  // );

  // console.log(trendingCommunities, "trendingCommunities");

  // const selectedCommunity = useSelector(() => {
  //   return state?.selectedCommunity;
  // });

  const [copied, setCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleJoinCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCopyLink = () => {
    if (props?.status === 'PUBLISHED') {
      const labelContent = `${USER_APP_BASE_URL}/community/${props?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${props?._id}`;

      navigator.clipboard
        .writeText(labelContent)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
          enqueueSnackbar('Link copied successfully', { variant: 'success' });
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
        });
    } else {
      // Handle the case when the status is not "PUBLISHED"
      enqueueSnackbar('Cannot copy link for non-published items', {
        variant: 'error',
      });
    }
  };

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${props?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${props?._id}`
  )}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${props?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${props?._id}`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${props?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${props?._id}`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${props?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${props?._id}`
  )}`;


  return (
    <>
      <List
        sx={{
          width: '50vw',
          maxWidth: '100%',
          bgcolor: 'background.paper',
          p: 0.5,
          borderRadius: '10px',
        }}
      >
        <React.Fragment>
          <Box sx={{ m: 1 }} alignItems={'center'}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Stack direction="row" alignItems="center">
                <Avatar alt="Community Logo" src={props?.logo} />
                <Box sx={{ ml: 2 }}>
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 500, color: '#000000' }}
                  >
                    {props?.name}
                  </Typography>
                  <Typography sx={{ color: '#787878', fontSize: '12px' }}>
                    {props?.city}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                alignItems="center"
                justifyContent={'center'}
                sx={{ ml: 2 }}
              >
                <Button
                  onClick={handleJoinCommunity}
                  sx={{
                    textTransform: 'none',
                    fontSize: '13px',
                    backgroundColor: '#3C5AA0',
                    color: '#ffffff',
                    fontWeight: '500',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#3C5AA0',
                    },
                  }}
                >
                  Join
                </Button>
                <SuggestionsPreview
                  isOpen={isModalOpen}
                  handleClose={handleCloseModal}
                  logo={props?.logo}
                  id={props?._id}
                  title={props?.name}
                  city={props?.city}
                  members={props?.members?.length || []}
                  message={props?.message}
                  type={props?.type}
                  description={props?.description}
                  handleCopyLink={handleCopyLink}
                  copied={copied}
                  whatsappShareUrl={whatsappShareUrl}
                  facebookShareUrl={facebookShareUrl}
                  linkedinShareUrl={linkedinShareUrl}
                  twitterShareUrl={twitterShareUrl}
                  coverImageSrc={props?.banner}
                  refetchData={refetchData}
                />
              </Stack>
            </Stack>
          </Box>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      </List>
    </>
  );
};
