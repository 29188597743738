import React, { useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import store from '../../store';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Loader from '../Loader/Loader';
import Close from '@mui/icons-material/Close';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import { InsertPhotoOutlined } from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { usePlans } from '../../hooks/usePlans';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { metaConfig } from '../../utils/constants';
import { Helmet } from 'react-helmet';
import BreadCrumbs from '../AlternatePages/BreadCrumbs';
import { useLiveClasses } from '../../hooks/useLiveClassess';

dayjs.extend(utc);

interface Community {
  name: string;
  id: string;
}

export interface IAddLiveClass {
  name: string;
  description: string;
  instructorName: string;
  startDate: string;
  startTime: string;
  community: string;
  plan: any;
}

export interface ILiveClass {
  _id: string;
  name: string;
  description: string;
  instructorName: string;
  startDate: string;
  startTime: string;
  status: string;
  community: string;
  plan: string;
}

const AddLiveClass = () => {
  const navigate = useNavigate();

  const communityState = store.getState();
  const currentCommunity = communityState?.selectedCommunity?.selectedCommunity;
  // console.log(currentCommunity,"currentCommunity"

  const usersData = store.getState();
  // console.log(usersData)
  const [userId] = useState(usersData.loggedInUser.user?.id);

  // console.log(userId, "userId")

  const [value, setValue] = React.useState('YES');
  const [planDetail] = useState<any>();
  const [selectedPlan, setSelectedPlan] = useState<any>(planDetail);
  // console.log(selectedPlan);

  const [liveClassValues, setLiveClassValues] = useState<any>({
    name: '',
    description: '',
    instructorName: '',
    startDate: '',
    startTime: '',
    community: currentCommunity?._id ?? '',
    plan: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLiveClassValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const { id } = useParams();

  const { createLiveClass, createGoogleAuto, getClassById, updateLiveClass } = useLiveClasses()

  const handleSubmitGenerateAuth = async () => {
    try {
      const response = await createGoogleAuto(userId || "");
      if (response?.data?.authUrl) {
        window.open(response.data.authUrl, "_blank");
      }
      return response;
    } catch (err) {
      console.log(err, "error");
    }
  };


  const [community] = React.useState(false);
  const [options] = React.useState<Community[]>([]);
  const loading = community && options.length === 0;

  const [open, setOpen] = React.useState(false);
  const [planImages, setPlanImages] = useState<FileList | null>(null);
  // eslint-disable-next-line
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<Dayjs | null>(null);
  const [opensPublish, setOpensPublish] = React.useState(false);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [plans, setPlans] = useState<IAddLiveClass[]>([]);


  useEffect(() => {
    if (!id) return;
    const loadLiveClass = async () => {
      const data = await getClassById(selectedCommunityId, id || '');
      // console.log(data, 'data')
      const responseData = data[0];
      // console.log(responseData?.startTime, "startTime")
      if (responseData) {
        setLiveClassValues((prev: any) => ({
          ...prev,
          name: responseData.name || '',
          description: responseData.description || '',
          instructorName: responseData.instructorName || '',
          startDate: formateStartDate(responseData.startDate) || '',
          startTime: responseData?.startTime || '',
          community: responseData.community || currentCommunity?._id || '',
        }));
        setSelectedPlan(responseData?.plan);
      }
    };
    loadLiveClass();
  }, [id]);


  const formateStartDate = (date: string) => {
    const startDate = date.slice(0, 10);
    setSelectedStartDate(dayjs.utc(startDate));
    return startDate;
  };

  const formatStartTime = (date: string) => {
    const formattedTime = dayjs.utc(date).format("HH:mm:ss");
    setSelectedStartTime(dayjs.utc(date));
    return formattedTime;
  };


  const handleMyDateChange = (e: any) => {
    const selectedNewDate = new Date(e);
    const formattedDate = selectedNewDate.toISOString().split('T')[0];
    setLiveClassValues({ ...liveClassValues, startDate: formattedDate });
    formateStartDate(formattedDate);

  };

  const handleMyTimeChange = (time: any) => {
    const formattedTime = dayjs(time).format("HH:mm:ss");
    setLiveClassValues({ ...liveClassValues, startTime: formattedTime });
    formatStartTime(formattedTime)
  };


  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLiveClassValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    setError(value === '');
  };
  // eslint-disable-next-line

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitClicked(true);
    liveClassValues.plan = selectedPlan?._id || '';
    liveClassValues.community = currentCommunity?._id || '';
    try {
      setLoader(true);
      if (id) {
        const updateResponse = await updateLiveClass(liveClassValues, id);
        // console.log(updateResponse, "updateResponse");
        enqueueSnackbar('Class updated successfully', { variant: 'success' });
        navigate('/classes');
        return updateResponse;
      }
      else {
        const createResponse = await createLiveClass(liveClassValues);
        navigate('/classes');
        return createResponse;
      }
    } catch (error: any) {
      console.error('Submission error:', error);
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
      enqueueSnackbar(`Error: ${errorMessage}`, { variant: 'error' });
    } finally {
      setLoader(false);
    }
  };


  //attach file new logic start
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');

  const handlePlanImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPlanImages(e.target.files);
    }
  };

  const handleClickOpenImg = () => {
    setOpen(true);
  };

  const handleClosesImg = () => {
    setOpen(false);
  };

  const locations = useLocation();
  const IsTitleDisplay = locations.pathname.includes('/plans/new') && id;

  const { getCommunityPlansList } = usePlans();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [plansList, setPlansList] = useState<ILiveClass[]>([]);

  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const loadPlansList = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const plans_data = await getCommunityPlansList(selectedCommunityId);
        if (plans_data) {
          setIsLoading(false);
          setPlansList(plans_data);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log(plansList);
  useEffect(() => {
    loadPlansList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);

  const plansTitles = plansList ? plansList.map((plan) => plan?.name) : [];

  const handleClickOpenPublish = () => {
    setOpensPublish(true);
  };

  const handleClickClosesPublish = () => {
    setOpensPublish(false);
  };


  return (
    <>
      <Helmet>
        <title>{metaConfig.title.addLiveClass}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <BreadCrumbs
        title="Classes"
        subTitle={id ? 'Update Class' : 'New Class'}
        link="/classes"
      />
      <div>
        {loading ? (
          <Loader />
        ) : (
          <Stack direction={'column'} spacing={1}>
            <Stack sx={{ display: IsTitleDisplay ? 'block' : 'none' }}>
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: '#3760A9',
                  fontFamily: 'Inter',
                }}
              >
                {id ? 'Publish' : 'Create'} a Course
              </Typography>
            </Stack>

            <Box
              sx={{
                backgroundColor: 'white',
                pl: 1,
                pr: 1,
                pb: 1,
                pt: 1,
                borderRadius: '5px',
                height: { xs: 'fit-content', md: 'fit-content' },
              }}
            >
              {!id &&
                <>
                  <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={0.5}>
                    <Typography>
                      Connect your gmail account
                    </Typography>
                    <Button
                      sx={{
                        color: "#3C5AA0",
                        textTransform: 'lowercase',
                        textDecoration: 'underline'
                      }}
                      onClick={handleSubmitGenerateAuth}
                    >
                      here
                    </Button>
                  </Stack>
                </>
              }

              <>
                <Box
                  sx={{
                    borderRadius: '10px',
                  }}
                >
                  <Grid
                    container
                    sx={{ borderRadius: '10px', padding: '5px' }}
                    gap={5}
                  >
                    <Grid item xs={12} md={8}>
                      <Stack spacing={1} sx={{ m: 1 }}>
                        <Stack>
                          <Stack>
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Inter',
                              }}
                            >
                              Class Name
                            </Typography>
                            <TextField
                              required
                              name="name"
                              label="Name"
                              size="small"
                              onChange={handleChanges}
                              value={liveClassValues?.name}
                              InputLabelProps={{
                                style: { fontSize: '13px', color: '#565656' },
                              }}
                              sx={{
                                mt: 1,
                                mr: 1,
                                mb: 1,
                                fontSize: '13px',
                                backgroundColor: 'white',
                              }}
                            />
                          </Stack>
                        </Stack>
                        <Stack>
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Inter',
                              }}
                            >
                              Class Description
                            </Typography>
                            <TextField
                              name="description"
                              id="description"
                              label="Description"
                              size="medium"
                              value={liveClassValues?.description}
                              onChange={handleChanges}
                              multiline
                              rows={2}
                              InputLabelProps={{
                                style: { fontSize: 13, color: '#565656' },
                              }}
                              sx={{ mt: 1, mr: 1, backgroundColor: 'white' }}
                            />
                          </Stack>
                        </Stack>
                        <Stack>
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Inter',
                              }}
                            >
                              Instructor Name
                            </Typography>
                            <TextField
                              name="instructorName"
                              id="instructor"
                              label="Instructor Name"
                              size="small"
                              value={liveClassValues?.instructorName}
                              onChange={handleChanges}
                              InputLabelProps={{
                                style: { fontSize: 13, color: '#565656' },
                              }}
                              sx={{ mt: 1, mr: 1, backgroundColor: 'white' }}
                            ></TextField>
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack sx={{ m: 1 }}>
                        <Grid container spacing={{ xs: 1, md: 5 }}>
                          <Grid item xs={12} md={6}>
                            <Stack>
                              <Stack sx={{}}>
                                <Typography
                                  sx={{
                                    mt: 1,
                                    fontSize: '13px',
                                    color: '#2653A3',
                                    fontWeight: '500',
                                    fontFamily: 'Inter',
                                  }}
                                >
                                  Select Date
                                </Typography>
                              </Stack>
                              <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                <DemoContainer
                                  components={['DesktopDatePicker']}
                                  sx={{
                                    overflow: 'hidden',
                                    backgroundColor: 'white',
                                    mt: 1,
                                    p: 0,
                                    boxShadow: 'none'
                                  }}
                                >
                                  <DatePicker
                                    value={selectedStartDate}
                                    label="Select Date"
                                    disablePast
                                    onChange={handleMyDateChange}
                                    sx={{
                                      fontSize: '12px',
                                      borderRadius: '10px',
                                      backgroundColor: 'white',
                                      boxShadow: 'none',
                                      '& .css-q9e13y-MuiStack-root>.MuiTextField-root':
                                      {
                                        backgroundColor: 'white',
                                      },
                                    }}
                                    slotProps={{
                                      textField: {
                                        size: 'small',
                                        fullWidth: true,
                                        sx: {
                                          '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error':
                                          {
                                            color: '#00000061',
                                          },
                                          '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                          {
                                            borderColor: 'green',
                                            border: '1px solid',
                                          },
                                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                                          {
                                            color: '#92929D',
                                            fontSize: '13px',
                                          },
                                        },
                                      },
                                      layout: {
                                        sx: {
                                          color: '#2653A3',
                                          borderRadius: '20px',
                                          borderWidth: '0px',
                                          borderColor: '#2653A3',
                                          border: '0px solid',
                                          backgroundColor: 'white',
                                          fontFamily: 'Inter',
                                          boxShadow: 'none'
                                        }
                                      }
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={5.9}>
                            <Stack>
                              <Typography
                                sx={{
                                  mt: 1,
                                  fontSize: '13px',
                                  color: '#2653A3',
                                  fontWeight: '500',
                                  fontFamily: 'Inter',
                                }}
                              >
                                Select Time
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={['TimePicker']}
                                  sx={{
                                    overflow: 'hidden',
                                    backgroundColor: 'white',
                                    mt: 1,
                                    p: 0,
                                  }}
                                >
                                  <MobileTimePicker
                                    value={selectedStartTime}
                                    label="Select Time"
                                    onChange={handleMyTimeChange}
                                    sx={{
                                      fontSize: '12px',
                                      backgroundColor: 'white',
                                      '& .MuiClock-clock': {
                                        backgroundColor: '',
                                        borderRadius: '50%',
                                        border: '2px solid #ad1457',
                                      },
                                      '& .MuiClock-pin': {
                                        backgroundColor: '#ad1457',
                                      },

                                    }}
                                    slotProps={{
                                      textField: {
                                        size: 'small',
                                        fullWidth: true,
                                      },
                                    }}
                                  />

                                </DemoContainer>
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                        </Grid>

                        <Grid container spacing={5} sx={{ pr: 1 }}>
                          <Grid item xs={12} md={6}>
                            <Stack sx={{}}>
                              <Typography
                                sx={{
                                  ml: 1,
                                  mt: 3,
                                  fontSize: '13px',
                                  color: '#2653A3',
                                  fontWeight: '500',
                                  fontFamily: 'Inter',
                                }}
                              >
                                Select Plan
                              </Typography>
                            </Stack>{' '}
                            <Stack sx={{}}>
                              <Autocomplete
                                options={plansTitles}
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                loading={isLoading}
                                sx={{ mt: 1, fontSize: '12px' }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Plan"
                                    sx={{ fontSize: '10px' }}
                                  />
                                )}
                                ListboxProps={{
                                  sx: { fontSize: '13px', font: 'Roboto', borderRadius: '30px' },
                                }}
                                noOptionsText="No Plans"
                                value={selectedPlan?.name || ''}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    const selectedPlanObject = plansList?.find((plan) => plan?.name === newValue);
                                    setSelectedPlan(selectedPlanObject || null);
                                  } else {
                                    setSelectedPlan(null);
                                  }
                                }}
                              />

                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack sx={{ mt: 2 }}>
                              <FormControl>
                                <FormLabel
                                  id="demo-row-radio-buttons-group-label"
                                  sx={{
                                    mt: 1,
                                    fontSize: '13px',
                                    color: '#2653A3',
                                    fontWeight: '500',
                                    fontFamily: 'Inter',
                                  }}
                                >
                                  Select Privacy
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="pricing"
                                  value={value}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    value="YES"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="NO"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}></Grid>
                          <Grid item xs={12} md={5.9}></Grid>
                        </Grid>
                      </Stack>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={3.5}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '5px',
                      }}
                    >
                      <Typography
                        sx={{
                          ml: 1,
                          mt: 1,
                          fontSize: '13px',
                          color: '#2653A3',
                          fontWeight: '500',
                          fontFamily: 'Inter',
                        }}
                      >
                        Upload Cover Image
                      </Typography>
                      <Stack
                        sx={{
                          backgroundColor: '#F5F5F5',
                          p: 1.5,
                          m: 1,
                          borderRadius: '5px',
                          border: '1px dashed #000000',
                          height: { xs: '20vh', md: '25vh' },
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundImage: liveClassValues?.coverImage?.value
                            ? `url(${liveClassValues?.coverImage?.value})`
                            : 'none',
                          backgroundPosition: 'center',
                        }}
                      >
                        <AddPhotoAlternateOutlinedIcon
                          sx={{ fontSize: '40px', color: '#5E6C84', pb: 1 }}
                        />
                        <Typography
                          sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}
                        >
                          Upload Cover Image
                        </Typography>
                        <input
                          type="file"
                          name="images"
                          onChange={handlePlanImages}
                          style={{
                            border: '2px solid #5E6C84',
                            borderRadius: '5px',
                            padding: '8px',
                            backgroundColor: '#ffffff',
                            color: '#5E6C84',
                            fontSize: '10px',
                            fontWeight: '500',
                            cursor: 'pointer',
                            width: '70%',
                            marginTop: '10px',
                          }}
                        />
                      </Stack>
                      <Typography
                        sx={{
                          ml: 1,
                          mt: 3,
                          fontSize: '13px',
                          color: '#2653A3',
                          fontWeight: '500',
                          fontFamily: 'Inter',
                        }}
                      >
                        Upload Notes
                      </Typography>
                      <Stack
                        sx={{
                          backgroundColor: '#F5F5F5',
                          p: 1.5,
                          m: 1,
                          borderRadius: '5px',
                          border: '1px dashed #000000',
                          height: { xs: '20vh', md: '25vh' },
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundImage: liveClassValues?.coverImage?.value
                            ? `url(${liveClassValues?.coverImage?.value})`
                            : 'none',
                          backgroundPosition: 'center',
                        }}
                      >
                        <AddPhotoAlternateOutlinedIcon
                          sx={{ fontSize: '40px', color: '#5E6C84', pb: 1 }}
                        />
                        <Typography
                          sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}
                        >
                          Upload Document
                        </Typography>
                        <input
                          type="file"
                          name="images"
                          onChange={handlePlanImages}
                          style={{
                            border: '2px solid #5E6C84',
                            borderRadius: '5px',
                            padding: '8px',
                            backgroundColor: '#ffffff',
                            color: '#5E6C84',
                            fontSize: '10px',
                            fontWeight: '500',
                            cursor: 'pointer',
                            width: '70%',
                            marginTop: '10px',
                          }}
                        />
                      </Stack>
                      <Stack>
                        {liveClassValues?.image && (
                          <Button
                            variant="outlined"
                            onClick={handleClickOpenImg}
                            sx={{
                              textTransform: 'capitalize',
                              mt: { xs: 2, md: 1.5 },
                              fontSize: '13px',
                              fontWeight: 'defult',
                              width: '160px',
                              color: '#5E6C84',
                              border: '1px solid #5E6C84',
                              ':hover': {
                                color: '#5E6C84',
                                border: '1px solid #5E6C84',
                              },
                            }}
                            startIcon={<InsertPhotoOutlined />}
                          >
                            Preview Image
                          </Button>
                        )}

                        {/* Pop Up for About us Content */}
                        <Dialog
                          fullWidth={fullWidth}
                          maxWidth={maxWidth}
                          open={open}
                          onClose={handleClosesImg}
                        >
                          <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                            <Stack
                              direction={'row'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              sx={{ backgroundColor: '#F0F9FF' }}
                            >
                              <Stack></Stack>
                              <Stack></Stack>
                              <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                                <Box
                                  onClick={handleClosesImg}
                                  sx={{
                                    backgroundColor: '#3C5AA0',
                                    padding: '4px',
                                    borderRadius: '50%',
                                    width: '20px',
                                    height: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    fontSize: '10px',
                                    mt: '0.5px',
                                    mr: '10px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                      // backgroundColor: "#50A1CA", // Background color on hover
                                      cursor: 'pointer',
                                      border: 'none',
                                    },
                                  }}
                                >
                                  <Close sx={{ fontSize: '13px' }} />
                                </Box>
                              </Stack>
                            </Stack>
                          </DialogActions>

                          <DialogContent>
                            <img
                              alt=""
                              // onClick={() => navigate(`/plans/${plan?._id}`)}
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '300px',
                                objectFit: 'cover',
                                borderRadius: '4px',
                                cursor: 'pointer',
                              }}
                            // src={
                            //   courseValues?.coverImage
                            //     ? courseValues?.coverImage?.value
                            //     : " "
                            // }
                            ></img>
                          </DialogContent>
                        </Dialog>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container sx={{ mt: 2, pb: { xs: 5, md: 2 }, p: '8px', ml: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Stack
                      direction="row"
                      spacing={2}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        sx={{
                          background:
                            'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                          textTransform: 'capitalize',
                          border: 'none',
                          borderRadius: '8px',
                          boxShadow: 'none',
                          color: 'white',
                          '&:hover': {
                            background:
                              'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                            cursor: 'pointer',
                            color: 'white',
                            border: 'none',
                            boxShadow: 'none',
                          },
                        }}
                        onClick={handleClickOpenPublish}
                      >
                        {id ? "Reschedule" : "Schedule"}
                      </Button>
                      <Typography sx={{ color: 'red' }}>
                        {' '}
                        {errorMessage}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        ml: 1,
                        mt: 1,
                        fontSize: '13px',
                        color: '#B92C0B',
                        fontWeight: '500',
                        fontFamily: 'Inter',
                      }}
                    >
                      * Your meeting will be scheduled in Google meet
                    </Typography>


                    {/* Pop Up for About us Content */}
                    <Dialog
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      open={opensPublish}
                      onClose={handleClickClosesPublish}
                    >
                      <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ backgroundColor: '#F0F9FF' }}
                        >
                          <Typography
                            sx={{
                              fontSize: '20px',
                              fontWeight: 500,
                              textDecoration: 'none',
                              color: '#3C5AA0',

                              marginLeft: 2,
                            }}
                          >
                            {'Schedule the Class?'}
                          </Typography>
                          <Box
                            onClick={handleClickClosesPublish}
                            sx={{
                              backgroundColor: '#3C5AA0',
                              padding: '4px',
                              borderRadius: '50%',
                              width: '20px',
                              height: '20px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: 'white',
                              fontSize: '10px',
                              mt: '0.5px',
                              mr: '10px',
                              cursor: 'pointer',
                              '&:hover': {
                                cursor: 'pointer',
                                border: 'none',
                              },
                            }}
                          >
                            <Close sx={{ fontSize: '15px' }} />
                          </Box>
                        </Grid>
                      </DialogActions>

                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          sx={{ fontSize: '13px', textAlign: 'center' }}
                        >
                          Once you publish your subscription plan, you cannot
                          make any more changes to it. This is to be
                          transparent and build trust with subscribers. Are
                          you ready to publish the plan?.
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button
                          onChange={(e: any) => setLiveClassValues(e.target.value)}
                          onClick={handleSubmit}
                          size="large"
                          variant="contained"
                          sx={{
                            background:
                              'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                            textTransform: 'capitalize',
                            border: 'none',
                            borderRadius: '10px',
                            boxShadow: 'none',
                            color: 'white',
                            '&:hover': {
                              background:
                                'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                              cursor: 'pointer',
                              color: 'white',
                              border: 'none',
                              boxShadow: 'none',
                            },
                          }}
                        >
                          {loader ? (
                            <CircularProgress
                              size={24}
                              sx={{ color: 'white' }}
                            />
                          ) : (
                            <>
                              {id ? "Reschedule" : "Schedule"}
                            </>
                          )}
                        </Button>
                        <Button
                          size="large"
                          sx={{
                            textTransform: 'capitalize',
                            // fontWeight:600,
                            color: '#50A1CA',
                            border: '1px solid #50A1CA',
                            borderRadius: '10px',
                            boxShadow: 'none',
                            '&:hover': {
                              cursor: 'pointer',
                              border: '1px solid #50A1CA',
                              boxShadow: 'none',
                            },
                          }}
                          onClick={handleClickClosesPublish}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </>
            </Box>
          </Stack>
        )}
      </div>
    </>
  );
};
export default AddLiveClass;
