
import { SubNavbar } from '../Layout/components/SubNavbar'
import { Breadcrumbs, Typography } from '@mui/material'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { Link } from 'react-router-dom';

interface IBreadCrumbs {
    title?: string;
    subTitle?: string;
    link?: string;
}

const BreadCrumbs = (props: IBreadCrumbs) => {
    return (
        <>
            <SubNavbar>
                <Breadcrumbs aria-label="breadcrumb" separator={<KeyboardDoubleArrowRightOutlinedIcon fontSize="small" />}>
                    <Link
                        to={props?.link || '#'}
                        style={{
                            fontSize: window.innerWidth < 600 ? '15px' : '20px',
                            fontWeight: 500,
                            color: 'inherit',
                            fontFamily: 'Inter',
                            textDecoration: 'none'
                        }}
                    >
                        {props?.title}
                    </Link>
                    <Typography
                        sx={{
                            fontSize: { xs: '15px', md: '20px' },
                            fontWeight: 500,
                            color: '#3C5AA0',
                            fontFamily: 'Inter',
                        }}
                    >
                        {props?.subTitle}
                    </Typography>
                </Breadcrumbs>
            </SubNavbar>
        </>
    )
}

export default BreadCrumbs
