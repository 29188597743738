import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import {
  fetchAllLiveClasses,
  fetchAllPastClasses,
  fetchClassesById,
  generateAuth,
  scheduleClass,
  updateScheduleClass,
} from '../services/liveClasses.service';
import { ICreateClass } from '../models/liveClasses.model';
import { useNavigate } from 'react-router-dom';


export const useLiveClasses = () => {


  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();


  const createGoogleAuto = async (userId: string) => {
    try {
      const response = await generateAuth(getAccessToken(), userId);
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't fetch Live Classes", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };


  const createLiveClass = async (classData: ICreateClass) => {
    try {
      const response = await scheduleClass(getAccessToken(), classData);
      if (response?.status === 201) {
        enqueueSnackbar('Live Class added successfully.', { variant: 'success' });
        navigate('/classes');
      } else {
        enqueueSnackbar("Couldn't create post", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response
    } catch (error) {
      enqueueSnackbar("Couldn't Create Live Class", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };


  const updateLiveClass = async (classData: ICreateClass, classId: string) => {
    try {
      const response = await updateScheduleClass(getAccessToken(), classData, classId);
      return { classes: response };
    } catch (error) {
      enqueueSnackbar("Couldn't fetch Live Classes", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };


  const getAllLiveClasses = async (id: string) => {
    try {
      const response = await fetchAllLiveClasses(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {
    }
  };


  const getAllPastClasses = async (id: string) => {
    try {
      const response = await fetchAllPastClasses(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {

    }
  };



  const getClassById = async (communityId: string, classId: string) => {
    try {
      const response = await fetchClassesById(getAccessToken(), communityId, classId);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {
      console.log(error, "error")
    }
  };



  return {
    getAllLiveClasses,
    getAllPastClasses,
    getClassById,
    createLiveClass,
    createGoogleAuto,
    updateLiveClass
  };
};
