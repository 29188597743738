import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { SubNavbar } from '../../Layout/components/SubNavbar';
import { NewAddPlan } from '../NewAddPlan';
import Close from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useSelector } from 'react-redux';
import store from '../../../store';
import { usePlans } from '../../../hooks/usePlans';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getStaticValue } from '../../../utils/StaticValues';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AuthContext, IAuthContext } from '../../../contexts/Auth.context';
import { createSubscription, updateSubscriptionById } from '../../../services/subscription.service';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import { useSubscription } from '../../../hooks/useSubscription';

export interface IUser {
  firstName?: string;
  lastName?: string;
  emailId?: string;
  phoneNumber?: string;
}

export interface IAddSubscription {
  user?: IUser;
  plan?: string;
  startDate?: string;
}

export interface IPlanData {
  _id: string;
  name: string;
  description: string;
  endDate: string;
  pricing: string;
  status: string;
}

const CreateSubscription = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openPlans, setOpenPlans] = useState(false);
  const [planDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [plansList, setPlansList] = useState<IPlanData[]>([]);
  const { getCommunityPlansList } = usePlans();
  const [selectedPlan, setSelectedPlan] = useState<any>(planDetail);

  const { id } = useParams();

  const [userData, setUserData] = useState<IUser>({
    firstName: '',
    phoneNumber: '',
    emailId: '',
  });
  const [errors, setErrors] = useState<IUser>({
    firstName: '',
    phoneNumber: '',
    emailId: '',
  });
  const [fullWidth] = useState(true);
  const [maxWidth] = useState<DialogProps['maxWidth']>('lg');
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(null);
  const [show, setShow] = useState(false);
  const [immediateStart, setImmediateStart] = useState(false);



  const handleCheckboxChange = (event: any) => {
    setImmediateStart(event.target.checked);
    if (event.target.checked) {
      setSelectedStartDate(dayjs());
    }
  };

  const handleDateChange = (newValue: any) => {
    setSelectedStartDate(newValue);
  };

  const handleClickShow = () => {
    setShow((prev) => !prev);
  };


  const navigate = useNavigate();

  const subscriptionId = id

  const { subscriptionListBySubscriptionId } = useSubscription();

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      if (subscriptionId) {
        try {
          const response = await subscriptionListBySubscriptionId(selectedCommunityId, subscriptionId);
          // console.log(response, "response");
          if (response) {
            const { user, plan, startDate } = response;
            // console.log("Start Date:", startDate);
            setUserData({
              firstName: user.firstName,
              lastName: user.lastName,
              phoneNumber: user.phoneNumber,
              emailId: user.emailId,
            });
            setSelectedPlan(plan);
            setSelectedStartDate(dayjs(startDate));
          }
        } catch (error) {
          console.error("Error fetching subscription data:", error);
        }
      }
    };

    fetchSubscriptionData();
  }, [subscriptionId]);



  const subscriptionDetails: IAddSubscription = {
    user: {
      firstName: userData?.firstName,
      lastName: userData?.lastName || ' ',
      phoneNumber: userData?.phoneNumber,
      emailId: userData?.emailId,
    },
    plan: selectedPlan?._id || '',
    startDate: selectedStartDate ? selectedStartDate.toISOString() : '',
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (subscriptionId) {
        const updateSubscription = await updateSubscriptionById(
          getAccessToken(),
          subscriptionId,
          subscriptionDetails
        );
        if (updateSubscription.data && updateSubscription.status === 200) {
          enqueueSnackbar('Subscription updated successfully', { variant: 'success' });
          navigate('/subscribers');
        } else {
          enqueueSnackbar('Failed to update subscription', { variant: 'error' });
        }
      } else {
        const response = await createSubscription(
          getAccessToken(),
          selectedCommunityId,
          subscriptionDetails
        );
        if (response.status === 201) {
          navigate('/subscribers');
          enqueueSnackbar('Subscription created successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else if (response.status === 401) {
          console.error('Error creating subscription:', response.data);
          enqueueSnackbar('User already Added', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        } else {
          console.error('Error creating subscription:', response.data);
          enqueueSnackbar('Subscription not Created', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
      setLoading(false);
    }
  };




  const dateString = selectedPlan?.startDate;
  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toLocaleDateString('en-US');

  const state = store.getState();

  const selectedCommunity = useSelector(() => {
    return state?.selectedCommunity;
  });

  const communitiesSuggestion = useSelector(() => {
    return state?.communities;
  });

  let filteredSelectedCommunity: any = [];
  if (Array.isArray(communitiesSuggestion?.communities)) {
    filteredSelectedCommunity = communitiesSuggestion.communities.filter(
      (community: any) =>
        community._id === selectedCommunity.selectedCommunity?._id
    );
  }

  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const formateStartDate = (date: string) => {
    const startDate = date.slice(0, 10);
    setSelectedStartDate(dayjs.utc(startDate));
    return startDate;
  };

  //   Plans List
  const loadPlans = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const plans_data = await getCommunityPlansList(selectedCommunityId);
        if (plans_data) {
          setIsLoading(false);
          setPlansList(plans_data);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(plansList);
  useEffect(() => {
    loadPlans();
  }, [selectedCommunityId]);

  const handleClickOpenPlans = () => {
    setOpenPlans(true);
  };
  const handleClose = () => {
    setOpenPlans(false);
  };

  const disableReview =
    userData?.firstName &&
    userData?.emailId &&
    userData?.phoneNumber &&
    selectedPlan &&
    selectedStartDate;


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      if (/^\d{0,10}$/.test(value)) {
        setUserData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: '',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: 'Please enter a valid mobile number',
        }));
      }
    } else {
      setUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


  return (
    <>
      <Helmet>
        <title>{id ? "Update" : "Create"} Subscription</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <SubNavbar>
        <Stack>
          <Typography
            sx={{
              fontSize: { xs: '15px', md: '20px' },
              fontWeight: 500,
              color: '#3C5AA0',
              fontFamily: 'Inter',
            }}
          >
            {id ? "Update" : "Create"} Subscription
          </Typography>
        </Stack>
        <Stack direction={'row-reverse'} flexGrow={1}>
          <Button
            variant="contained"
            onClick={handleClickOpenPlans}
            startIcon={<AddCircleRoundedIcon />}
            sx={{
              textTransform: 'capitalize',
              borderRadius: '30px',
              backgroundColor: '#3C5AA0',
              fontFamily: 'Inter',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3C5AA0',
                cursor: 'pointer',
                border: 'none',
              },
            }}
          >
            Create Plan
          </Button>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={openPlans}
            onClose={handleClose}
          >
            <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: '#F0F9FF' }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    textDecoration: 'none',
                    color: '#3C5AA0',

                    marginLeft: 2,
                  }}
                >
                  Create Plan
                </Typography>
                <Box
                  onClick={handleClose}
                  sx={{
                    backgroundColor: '#3C5AA0',
                    padding: '4px',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '10px',
                    mt: '0.5px',
                    mr: '10px',
                    cursor: 'pointer',
                    '&:hover': {
                      cursor: 'pointer',
                      border: 'none',
                    },
                  }}
                >
                  <Close sx={{ fontSize: '15px' }} />
                </Box>
              </Grid>
            </DialogActions>
            <DialogContent>
              <NewAddPlan
                selectedCommunity={filteredSelectedCommunity}
                closeDialog={handleClose}
                loadPlans={loadPlans}
              />
            </DialogContent>
          </Dialog>
        </Stack>
      </SubNavbar>

      {/* Sections 1 */}

      <Paper sx={{ py: 5, px: 2, boxShadow: 'none' }}>
        <Stack
          sx={{
            display: { xs: 'inline-block', sm: 'block', md: 'flex' },
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          }}
          gap={1}
          spacing={{ xs: 1, md: 0 }}
        >
          <Stack>
            <Stack sx={{}}>
              <TextField
                label="Name"
                name="firstName"
                variant="outlined"
                size="small"
                fullWidth
                value={userData?.firstName}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
                disabled={!!id}
                sx={{
                  fontSize: '12px',
                  width: { xs: '85vw', md: '15vw' },
                }}
              />
            </Stack>
          </Stack>
          <Stack>
            <Stack sx={{}}>
              <TextField
                name="phoneNumber"
                type="text"
                label="Mobile Number"
                variant="outlined"
                size="small"
                fullWidth
                value={userData?.phoneNumber}
                onChange={handleChange}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                disabled={!!id}
              />
            </Stack>
            <Stack sx={{}}></Stack>
          </Stack>
          <Stack>
            <Stack sx={{}}>
              <TextField
                type="mail"
                label="Email"
                name="emailId"
                variant="outlined"
                size="small"
                fullWidth
                value={userData?.emailId}
                onChange={handleChange}
                error={!!errors.emailId}
                helperText={errors.emailId}
                disabled={!!id}
              />
            </Stack>
          </Stack>
          <Stack>
            <Stack sx={{}}>
              <Autocomplete
                disabled={!!id}
                ListboxProps={{
                  style: { fontSize: '13px', fontFamily: 'Roboto' },
                }}
                options={plansList.filter((plan) => plan.status === 'ACTIVE')}
                size="small"
                disablePortal
                id="combo-box-demo"
                loading={isLoading}
                sx={{ fontSize: '13px', fontFamily: 'Roboto' }}
                getOptionLabel={(option) =>
                  `${option?.name || ''} - ₹${option?.offerValue === 0 ? option?.totalPlanValue : option?.offerValue || ''}`
                }
                noOptionsText="No Plans"
                value={selectedPlan || null}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    const selectedPlanObject = plansList.find(
                      (plan) => plan.name === newValue.name
                    );
                    setSelectedPlan(selectedPlanObject || null);
                  } else {
                    setSelectedPlan(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      fontSize: '13px',
                      fontFamily: 'Roboto',
                      width: { xs: '85vw', md: '15vw' },
                    }}
                    {...params}
                    placeholder="Select Plan"
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={['DesktopDatePicker']}
                sx={{
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  p: 0,
                }}
              >
                <DatePicker
                  disabled={immediateStart}
                  value={selectedStartDate}
                  onChange={handleDateChange}
                  format="DD/MM/YYYY"
                  sx={{
                    fontSize: '12px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                    '& .css-q9e13y-MuiStack-root>.MuiTextField-root': {
                      backgroundColor: 'white',
                      borderRadius: '10px',
                    },
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                      sx: {
                        '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error':
                        {
                          color: '#00000061',
                          borderRadius: '10px',
                        },
                        '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: 'green',
                          border: '1px solid',
                          borderRadius: '10px',
                        },
                        '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                        {
                          color: '#92929D',
                          fontSize: '13px',
                          borderRadius: '10px',
                        },
                        '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root':
                        {
                          borderRadius: '9px',
                        },
                        '& .css-tta2c4-MuiFormControl-root-MuiTextField-root .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root':
                        {
                          borderRadius: '9px',
                        },
                      },
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      transform: 'scale(1.1)',
                      color: '#92929D',
                      '&.Mui-checked': {
                        color: '#5E6C84',
                      },
                      '& .MuiSvgIcon-root': {
                        borderRadius: '4px',
                      },
                    }}
                  />
                }
                checked={immediateStart}
                onChange={handleCheckboxChange}
                label="Check if you need Immediately."
                sx={{
                  fontSize: '10px',
                  color: '#515151',
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  '& .MuiFormControlLabel-label': {
                    fontSize: { xs: '10px', md: '10px' },
                    color: '#515151',
                    fontWeight: '500',
                    fontFamily: 'Inter',
                  },
                }}
              />
            </FormGroup>
          </Stack>
        </Stack>
        {!id && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#3C5AA0',
              textTransform: 'capitalize',
              borderRadius: '5px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3C5AA0',
                color: 'white',
              },
            }}
            onClick={handleClickShow}
            disabled={!disableReview}
          >
            Review
          </Button>
        )}

      </Paper>
      {(id || show) && (
        <Paper sx={{ p: 5, mt: 2, mb: { xs: 4, md: 0 } }} elevation={0}>
          <Stack display="flex" flexDirection="row" gap={5}>
            <Stack>
              <Typography
                sx={{
                  ml: 1,
                  mt: 1,
                  fontSize: '13px',
                  color: '#515151',
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  textTransform: 'capitalize',
                }}
              >
                User
                <br />
                <span
                  style={{
                    fontSize: '12px',
                    color: '#515151',
                    fontWeight: '400',
                    fontFamily: 'Inter',
                  }}
                >
                  {userData?.firstName ? userData?.firstName : 'No User Selected'}
                </span>
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  ml: 1,
                  mt: 1,
                  fontSize: '13px',
                  color: '#515151',
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  textTransform: 'capitalize',
                }}
              >
                Plan
                <br />
                <span
                  style={{
                    fontSize: '12px',
                    color: '#515151',
                    fontWeight: '400',
                    fontFamily: 'Inter',
                  }}
                >
                  {selectedPlan?.name ? selectedPlan?.name : 'No Plan Selected'}
                </span>
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  ml: 1,
                  mt: 1,
                  fontSize: '13px',
                  color: '#515151',
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  textTransform: 'capitalize',
                }}
              >
                Start Date
                <br />
                <span
                  style={{
                    fontSize: '12px',
                    color: '#515151',
                    fontWeight: '400',
                    fontFamily: 'Inter',
                  }}
                >
                  {selectedStartDate
                    ? selectedStartDate.format('DD/MM/YYYY')
                    : 'No Date Selected'}
                </span>
              </Typography>
            </Stack>
          </Stack>
          <Stack display="flex" flexDirection="column" gap={2} mt={5}>
            <Stack>
              <Typography sx={{ fontSize: '13px', color: '#515151' }}>
                For every {selectedPlan?.interval}{' '}
                {selectedPlan?.interval > '1'
                  ? `${getStaticValue(selectedPlan?.duration)}s`
                  : getStaticValue(selectedPlan?.duration)}{' '}
                user is charged :
                <span
                  style={{
                    fontSize: '13px',
                    color: '#515151',
                    fontWeight: '600',
                    fontFamily: 'Inter',
                  }}
                >
                  ₹
                  {selectedPlan?.offerValue === 0
                    ? selectedPlan?.totalPlanValue
                    : selectedPlan?.offerValue}
                </span>
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: '13px', color: '#515151' }}>
                Receiving Payment :
                <span
                  style={{
                    fontSize: '13px',
                    color: '#515151',
                    fontWeight: '600',
                    fontFamily: 'Inter',
                  }}
                >
                  ₹
                  {selectedPlan?.offerValue === 0
                    ? selectedPlan?.totalPlanValue
                    : selectedPlan?.offerValue}
                </span>
              </Typography>
            </Stack>
          </Stack>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#3C5AA0',
              textTransform: 'capitalize',
              borderRadius: '5px',
              boxShadow: 'none',
              mt: 3,
              '&:hover': {
                backgroundColor: '#3C5AA0',
                color: 'white',
              },
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              <>{id ? 'Update User' : 'Add User'}</>
            )}
          </Button>
        </Paper>
      )}
    </>
  );
};
export default CreateSubscription;
