import {
    Box,
    Grid,
    Stack,
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import { useEffect, useState } from 'react';
import { InvitesAllCommunities } from '../AllCommunities/InvitesAllCommunities';
import { useInvitations } from '../../../hooks/useInvitations';
import { Invitations } from '../../../models/Invitation.model';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import { AlternativePage } from '../../AlternatePages/AlternativePage';
import NoInvites from "./../../../assets/icons/svg-icons/NoInvitation.png"
import { RotatingLines } from "react-loader-spinner"

interface ITableProps {
    searchQuery: string;
}


const InvitesCommunitiesNew = ({ searchQuery }: ITableProps) => {
    const [invitesCommunities, setInvitesCommunities] = useState<Invitations[]>([]);
    const { isLoading, getInvitesForUser } = useInvitations();


    useEffect(() => {
        const fetchData = async () => {
            const data = await getInvitesForUser();
            setInvitesCommunities(data);
        }
        fetchData();
    }, []);

    const filteredCommunities = invitesCommunities.filter((community) =>
        community.community?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );


    return (
        <>
            <Helmet>
                <title>{metaConfig.title.invitesCommunities}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>

            <Stack>
                {isLoading ? (
                    <Stack
                        sx={{
                            color: 'grey.500',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '40vh',
                        }}
                        spacing={2}
                        direction="row"
                    >
                        <RotatingLines
                            visible={true}
                            width="30"
                            strokeWidth="5"
                            strokeColor="#777777"
                            ariaLabel="rotating-lines-loading"
                        />
                    </Stack>
                ) : filteredCommunities.length > 0 ? (
                    <Grid container spacing={0}>
                        {filteredCommunities.map((item) => (
                            <Grid item key={item._id} xs={6} sm={2} md={1.5} lg={1.5} xl={1.5}>
                                <InvitesAllCommunities
                                    community={item?.community}
                                    _id={item?._id}
                                    message={item?.message}
                                    invites={item}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Box sx={{ textAlign: 'center', }}>
                        <AlternativePage title='No Invites ' image={NoInvites} />
                    </Box>
                )}
            </Stack>

        </>
    );
};

export default InvitesCommunitiesNew;
